import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { SEO, Layout, PageHeader, MainWrapper, StyledLink } from "components"

const MenuWrapper = styled.section`
  margin: 70px 0;
`

const Content = styled.div`
  display: grid;
  margin: 60px 0px 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 30px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const ContentImage = styled.div`
  min-height: 340px;
  position: relative;

  .gatsby-image-wrapper {
    width: inherit;
    height: inherit;
  }

  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    z-index: 101;
  }
`

const BackImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  img {
    border-radius: 20px;
  }
`

const MenuPage = () => (
  <Layout>
    <SEO title="Menu" />
    <PageHeader title="Menu">
      <StaticImage
        src="../images/slider/4.jpg"
        placeholder="blurred"
        alt="Menu"
        quality="100"
      />
    </PageHeader>
    <MenuWrapper>
      <MainWrapper>
        <Content>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../images/lunch-menu.jpg"
                placeholder="blurred"
                alt="Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="/lunch-menu"
              >
                Lunch Menu
              </StyledLink>
            </div>
          </ContentImage>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../images/dinner-menu.jpg"
                placeholder="blurred"
                alt="Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="/dinner-menu"
              >
                Dinner Menu
              </StyledLink>
            </div>
          </ContentImage>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../images/drinks-menu.jpg"
                placeholder="blurred"
                alt="Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="/drinks-menu"
              >
                Drinks Menu
              </StyledLink>
            </div>
          </ContentImage>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../images/spruzzo.malibu(tiramisu).jpg"
                placeholder="blurred"
                alt="Dessert Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="/dessert-menu"
              >
                Dessert Menu
              </StyledLink>
            </div>
          </ContentImage>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../images/happy-hour-menu.jpg"
                placeholder="blurred"
                alt="Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="/happy-hour-menu"
              >
                Happy Hour Menu
              </StyledLink>
            </div>
          </ContentImage>
          <ContentImage>
            <BackImage>
              <StaticImage
                src="../images/dessert-menu.jpg"
                placeholder="blurred"
                alt="Menu"
                height={340}
              />
            </BackImage>

            <div
              className="link"
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <StyledLink
                backgroundColor="var(--primaryColor)"
                to="/brunch-menu"
              >
                Brunch Menu
              </StyledLink>
            </div>
          </ContentImage>
        </Content>
      </MainWrapper>
    </MenuWrapper>
  </Layout>
)

export default MenuPage
